var url = {
    isTest: process.env.NODE_ENV === 'development',
    getHost: function () {
        if (this.isTest)
            return 'localhost:49900';
        return 'manage.muzixiaohua.cn';
    },
    getDomain: function () {
        if (this.isTest)
            return `http://${this.getHost()}`;
        return `http://${this.getHost()}`;
    },
    getController: function (controller, action) {
        if (action)
            return `${this.getDomain()}/${controller}/${action}`;
        else
            return `${this.getDomain()}/${controller}`;
    },
    //UE服务端接口
    getUE: function () {
        return this.getController('api/ueditor');
    },
    //UE配置地址
    getUEConfig: function () {
        if (this.isTest)
            return '/UEditor/';
        return '/vue/UEditor/';
    },
    //统一上传地址
    getUpload: function () {
        return "ws://" + this.getHost() + "/upload/common";
    },
    getCompany: function (str) {
        return this.getController('company', str);
    },
    getAccount(str) {
        return this.getController('api/account', str);
    },
    getCategory(str) {
        return this.getController('categoryinfo', str);
    },
    getSubject(str) {
        return this.getController('subjectinfo', str);
    },
    getCards(str) {
        return this.getController('cardsinfo', str);
    },
    // 已经添加 
    getSys(str) {
        return this.getController('api/sys', str);
    },
    getCollect(str) {
        return this.getController('api/collect', str);
    },
    getTeacher(str) {
        return this.getController('api/teacher', str);
    },
    getQuestion(str) {
        return this.getController('api/question', str);
    },
    getStrategy(str) {
        return this.getController('api/strategy', str);
    },
    getVideo(str) {
        return this.getController('api/Video', str);
    },
    getSys_BasicData(str) {
        return this.getController('api/sys_basicdata', str);
    },
    getSchool(str) {
        return this.getController('api/school', str);
    },
    getStuCase(str) {
        return this.getController('api/stucase', str);
    },
    getCommon(str) {
        return this.getController('api/common', str);
    },
    getStuCaseCollege(str) {
        return this.getController('api/stucasecollege', str);
    },
    //控制器添加  获取
    getApplyRecord(str) {
        return this.getController('api/ApplyRecord', str);
    },



}
export default url;