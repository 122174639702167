<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>系统参数</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>系统参数配置</span>
        </div>
        <el-form
          :model="form"
          ref="form"
          label-width="100px"
          :status-icon="true"
          @submit.native.prevent="submitForm('form')"
        >
          <el-alert type="warning" :closable="true">
            <b>系统参数配置，影响系统运行和结算，请谨慎操作！</b>
          </el-alert>
          <br />

          <el-form-item
            label="系统名称"
            prop="sysName"
            :rules="[{ required: true, message: '请输入系统名称' }]"
          >
            <el-input
              v-model="form.sysName"
              placeholder="请输入系统名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="公司名称"
            prop="company"
            :rules="[{ required: true, message: '请输入公司名称' }]"
          >
            <el-input
              v-model="form.company"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="siteDetail" :rules="[]">
            <el-input
              v-model="form.siteDetail"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>

          <el-divider content-position="left">小程序分享</el-divider>
          <el-form-item
            label="分享标题"
            prop="shareTitle"
            :rules="[{ required: true, message: '请输入分享标题' }]"
          >
            <el-input
              v-model="form.shareTitle"
              placeholder="请输入小程序首页分享标题"
            ></el-input>
          </el-form-item>

          <el-form-item label="分享卡片">
            <div id="uploadBtnConfig" class="uploader uploader-warning"></div>
            <img
              id="relativeName"
              :src="domain + form.shareImg"
              style="height: 40px; position: relative; left: 10px; top: 20px"
              v-if="form.shareImg"
            />
            <span class="red" style="margin-left:20px;">*注：图片比例要求：5:4 ，推荐尺寸：700*560</span>
          </el-form-item>

          <el-divider content-position="left">联系方式</el-divider>
          <el-form-item
            label="邮箱"
            prop="email"
            :rules="[
              { required: true, message: '请输入邮箱' },
              { type: 'email', message: '邮箱格式不正确' },
            ]"
          >
            <el-input
              v-model="form.email"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" :rules="[]">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="微信" prop="wechat">
            <el-input
              v-model="form.wechat"
              placeholder="请输入微信号"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服链接" prop="serviceLink" :rules="[]">
            <el-input
              v-model="form.serviceLink"
              placeholder="请输入客服链接地址"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item
            label="骑手佣金"
            prop="riderAmount"
            :rules="[{required:true,message:'不能为空'},{type:'number',message:'请填写数字'}]"
          >
            <el-input v-model.number="form.riderAmount" placeholder="请输入佣金"></el-input>
          </el-form-item>

          <el-form-item
            label="配送费"
            prop="distFee"
            :rules="[{required:true,message:'不能为空'},{type:'number',message:'请填写数字'}]"
          >
            <el-input v-model.number="form.distFee" placeholder="请输入配送费">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="结算费率"
            prop="poundage"
            :rules="[{required:true,message:'不能为空'},{type:'number',message:'请填写数字'}]"
          >
            <el-input v-model.number="form.poundage" placeholder="请输入结算费率值"></el-input>
          </el-form-item>
          -->
          <el-form-item>
            <el-button type="primary" :loading="submiting" native-type="submit"
              >提交</el-button
            >
            <!-- <el-button @click="dialogVisible=false">取消</el-button> -->
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
export default {
  data() {
    return {
      submiting: false,
      form: {}, //参数对象
    };
  },
  methods: {
    //获取配置信息
    getConfig: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      var link = url.getSys("getconfig");
      $.get(link, params, (data) => {
        _this.loading = false;
        _this.form = data;
      });
    },
    // 上传图片
    initImg: function() {
      var _this = this;
      if ($("#uploadBtnConfig input").length == 0) {
        $("#uploadBtnConfig").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          handleType: "0",
          maxSize: 1024 * 1024 * 0.5,
          onSuccess: function(data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "shareImg", data.relativeName);
          }
        });
      }
    },
  

    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getSys("saveconfig");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    //获取服务器列表
    this.getConfig();
    this.initImg();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
